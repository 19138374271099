import roboto from "gatsby-plugin-theme-ui";
import { merge } from "theme-ui";
import { hexToRgba } from "./utils";

const fontFaces = `
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 200 1000;
  font-style: normal;
  font-display: swap;
  src: url('/fonts/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype');
  font-optical-sizing: auto;
  font-variation-settings: "wdth" 100, "YTLC" 500;
}
`;

export default merge(roboto, {
  breakpoints: ["40em", "63em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: '"Nunito Sans", sans-serif',
    heading: '"Nunito Sans", sans-serif',
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 22, 28, 45],
  fontWeights: {
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
    extraBlack: 1000,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.4,
  },

  colors: {
    // Dark mode colors
    primary: "#E9C16C",
    primaryHover: hexToRgba("#E9C16C", 0.8),
    outlineButtonHover: hexToRgba("000000",0.2),
    surfaceTint: "#E9C16C",
    onPrimary: "#402D00",
    primaryContainer: "#5B4300",
    onPrimaryContainer: "#FFDF9E",
    secondary: "#D8C4A0",
    onSecondary: "#3B2F15",
    secondaryContainer: "#52452A",
    onSecondaryContainer: "#F5E0BB",
    tertiary: "#B0CFA9",
    onTertiary: "#1D361C",
    tertiaryContainer: "#334D30",
    tertiaryContainerHover: hexToRgba("#334D30", 0.8),
    onTertiaryContainer: "#CCEBC4",
    error: "#FFB4AB",
    onError: "#690005",
    errorContainer: "#93000A",
    errorContainerHover: hexToRgba("#93000A", 0.8),
    onErrorContainer: "#FFDAD6",
    background: "#17130B",
    onBackground: "#EBE1D4",
    surface: "#17130B",
    onSurface: "#EBE1D4",
    surfaceVariant: "#4D4639",
    onSurfaceVariant: "#D0C5B4",
    outline: "#998F80",
    outlineVariant: "#4D4639",
    shadow: "#000000",
    scrim: "#000000",
    inverseSurface: "#EBE1D4",
    inverseOnSurface: "#353027",
    inversePrimary: "#785A0B",
    primaryFixed: "#FFDF9E",
    onPrimaryFixed: "#261A00",
    primaryFixedDim: "#E9C16C",
    onPrimaryFixedVariant: "#5B4300",
    secondaryFixed: "#F5E0BB",
    onSecondaryFixed: "#241A04",
    secondaryFixedDim: "#D8C4A0",
    onSecondaryFixedVariant: "#52452A",
    tertiaryFixed: "#CCEBC4",
    onTertiaryFixed: "#072008",
    tertiaryFixedDim: "#B0CFA9",
    onTertiaryFixedVariant: "#334D30",
    surfaceDim: "#17130B",
    surfaceBright: "#3E382F",
    surfaceContainerLowest: "#110E07",
    surfaceContainerLow: "#1F1B13",
    surfaceContainer: "#231F17",
    surfaceContainerHigh: "#2E2921",
    surfaceContainerHighest: "#39342B",
    text:"onSurface",

    // modes: {
    //   dark: {
    //     // Dark mode colors
    //     primary: "#E9C16C",
    //     primaryHover: hexToRgba("#E9C16C", 0.8),
    //     outlineButtonHover: hexToRgba("000000", 0.2),
    //     surfaceTint: "#E9C16C",
    //     onPrimary: "#402D00",
    //     primaryContainer: "#5B4300",
    //     onPrimaryContainer: "#FFDF9E",
    //     secondary: "#D8C4A0",
    //     onSecondary: "#3B2F15",
    //     secondaryContainer: "#52452A",
    //     onSecondaryContainer: "#F5E0BB",
    //     tertiary: "#B0CFA9",
    //     onTertiary: "#1D361C",
    //     tertiaryContainer: "#334D30",
    //     tertiaryContainerHover: hexToRgba("#334D30", 0.8),
    //     onTertiaryContainer: "#CCEBC4",
    //     error: "#FFB4AB",
    //     onError: "#690005",
    //     errorContainer: "#93000A",
    //     errorContainerHover: hexToRgba("#93000A", 0.8),
    //     onErrorContainer: "#FFDAD6",
    //     background: "#17130B",
    //     onBackground: "#EBE1D4",
    //     surface: "#17130B",
    //     onSurface: "#EBE1D4",
    //     surfaceVariant: "#4D4639",
    //     onSurfaceVariant: "#D0C5B4",
    //     outline: "#998F80",
    //     outlineVariant: "#4D4639",
    //     shadow: "#000000",
    //     scrim: "#000000",
    //     inverseSurface: "#EBE1D4",
    //     inverseOnSurface: "#353027",
    //     inversePrimary: "#785A0B",
    //     primaryFixed: "#FFDF9E",
    //     onPrimaryFixed: "#261A00",
    //     primaryFixedDim: "#E9C16C",
    //     onPrimaryFixedVariant: "#5B4300",
    //     secondaryFixed: "#F5E0BB",
    //     onSecondaryFixed: "#241A04",
    //     secondaryFixedDim: "#D8C4A0",
    //     onSecondaryFixedVariant: "#52452A",
    //     tertiaryFixed: "#CCEBC4",
    //     onTertiaryFixed: "#072008",
    //     tertiaryFixedDim: "#B0CFA9",
    //     onTertiaryFixedVariant: "#334D30",
    //     surfaceDim: "#17130B",
    //     surfaceBright: "#3E382F",
    //     surfaceContainerLowest: "#110E07",
    //     surfaceContainerLow: "#1F1B13",
    //     surfaceContainer: "#231F17",
    //     surfaceContainerHigh: "#2E2921",
    //     surfaceContainerHighest: "#39342B",
    //     text: "onSurface",
    //   },
      // light: {
      //   primary: "#785A0B",
      //   primaryHover: hexToRgba("#785A0B", 0.8),
      //   outlineButtonHover: hexToRgba("#ffffff", 0.3),
      //   surfaceTint: "#785A0B",
      //   onPrimary: "#FFFFFF",
      //   primaryContainer: "#FFDF9E",
      //   onPrimaryContainer: "#261A00",
      //   secondary: "#6B5D3F",
      //   onSecondary: "#FFFFFF",
      //   secondaryContainer: "#F5E0BB",
      //   onSecondaryContainer: "#241A04",
      //   tertiary: "#4A6547",
      //   onTertiary: "#FFFFFF",
      //   tertiaryContainer: "#CCEBC4",
      //   tertiaryContainerHover: hexToRgba("#CCEBC4", 0.8),
      //   onTertiaryContainer: "#072008",
      //   error: "#BA1A1A",
      //   onError: "#FFFFFF",
      //   errorContainer: "#FFDAD6",
      //   errorContainerHover: hexToRgba("#FFDAD6", 0.8),
      //   onErrorContainer: "#410002",
      //   background: "#FFF8F2",
      //   onBackground: "#1F1B13",
      //   surface: "#FFF8F2",
      //   onSurface: "#1F1B13",
      //   surfaceVariant: "#EDE1CF",
      //   onSurfaceVariant: "#4D4639",
      //   outline: "#7F7667",
      //   outlineVariant: "#D0C5B4",
      //   shadow: "#000000",
      //   scrim: "#000000",
      //   inverseSurface: "#353027",
      //   inverseOnSurface: "#F9EFE2",
      //   inversePrimary: "#E9C16C",
      //   primaryFixed: "#FFDF9E",
      //   onPrimaryFixed: "#261A00",
      //   primaryFixedDim: "#E9C16C",
      //   onPrimaryFixedVariant: "#5B4300",
      //   secondaryFixed: "#F5E0BB",
      //   onSecondaryFixed: "#241A04",
      //   secondaryFixedDim: "#D8C4A0",
      //   onSecondaryFixedVariant: "#52452A",
      //   tertiaryFixed: "#CCEBC4",
      //   onTertiaryFixed: "#072008",
      //   tertiaryFixedDim: "#B0CFA9",
      //   onTertiaryFixedVariant: "#334D30",
      //   surfaceDim: "#E2D9CC",
      //   surfaceBright: "#FFF8F2",
      //   surfaceContainerLowest: "#FFFFFF",
      //   surfaceContainerLow: "#FCF2E5",
      //   surfaceContainer: "#F7ECDF",
      //   surfaceContainerHigh: "#F1E7D9",
      //   surfaceContainerHighest: "#EBE1D4",
      // },
    // },
  },
  initialColorModeName: "dark", // Set initial color mode to dark
  // printColorModeName: "light",
  shadows: {
    boxShadow1dp:
      "rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px",
    boxShadow3dp:
      "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;",
    boxShadow6dp:
      "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;",
    boxShadow8dp:
      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;",
    boxShadow12dp:
      "rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px;",
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "regular",
    },
    "@global": {
      "@font-face": fontFaces,
    },
    p: {
      fontWeight: "body",
      // fontFamily:"Nunito Sans"
    },
    h1: {
      // variant: "text.heading",
      fontSize: [4, 5],
      lineHeight: 1,
    },
    h2: {
      variant: "text.heading",
      fontSize: [4],
      lineHeight: "heading",
    },
    h3: {
      variant: "text.heading",
      fontSize: 3,
    },
    h4: {
      variant: "text.heading",
      fontSize: 2,
    },
    h5: {
      variant: "text.heading",
      fontSize: 1,
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    a: {
      color: "primary",
      textDecoration: "none",
      ":visited": {
        color: "primary",
      },
      ":hover": {
        color: "primary",
        textDecoration: "underline",
      },
      ":focus": {
        color: "primary",
      },
    },
    contactList: {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    contactListItemHeader: {
      justifyContent: "space-between",
      alignItems: "center",
      display: ["flex", "flex", "flex"],
      pb: [3, 0, 0],
      fontSize: 2,
    },
  },
  sizes: {
    container: 1028,
  },
  cards: {
    primary: {
      padding: [3, 4],
      borderRadius: 4,
      // boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      background: "surfaceContainerLow",
      color: "onSurface",
    },
    cardList: {
      // padding: [3, 4],
      // borderRadius: 4,
      // boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      // background: "surfaceContainer",
      px: 3,
      py: 4,
      mb: 2,
      color: "onSurface",
      // borderBottom:"1px solid red",
      // borderColor:"outlineVariant"
    },
    cardListUpdates: {
      // padding: [3, 4],
      // borderRadius: 4,
      // boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      // background: "surfaceContainer",
      px: 3,
      py: 4,
      mb: 0,
      color: "onSurface",
      borderBottom: "1px solid red",
      borderColor: "outlineVariant",
    },
    homepage: {
      padding: 0,
      borderRadius: 12,
      boxShadow: "boxShadow1dp",
      background: "surfaceContainer",
      color: "onSurface",
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: "1px solid",
      borderColor: "muted",
    },
    timeline: {
      position: "relative",
      margin: "0 0 20px 20px",
      padding: 4,
      borderRadius: 4,
      boxShadow: "boxShadow1dp",
      backgroundColor: "surfaceContainerLow",
    },
    roadmap: {
      position: "relative",
      margin: "16px 16px 16px 16px",
      backgroundColor: "surfaceContainerLow",
      padding: [3, 4],
      borderRadius: 4,
      boxShadow: "boxShadow1dp",
    },
    beforeduringaftercards: {
      color: "darkSandstone",
      borderRadius: 4,
      boxShadow: "0 0 8px rgba(0, 0, 0, 0.125)",
      backgroundColor: "surfaceContainerLow",
    },
    warning: {
      border: "0px solid",
      color: "onErrorContainer",
      borderColor: "none",
      borderRadius: 4,
      padding: 4,
      backgroundColor: "errorContainer",
      "&:hover": {
        backgroundColor: "errorContainerHover",
      },
    },
    advisory: {
      // border: "2px solid",
      // borderColor: "none",
      borderRadius: 4,
      padding: 4,
      backgroundColor: "errorContainer",
      color: "onErrorContainer",
      "&:hover": {
        backgroundColor: "errorContainerHover",
      },
    },
    no_alerts: {
      // border: "2px solid",
      // borderColor: "lightGray",
      borderRadius: 4,
      padding: 4,
      backgroundColor: "tertiaryContainer",
      color: "onTertiaryContainer",
      "&:hover": {
        backgroundColor: "tertiaryContainerHover",
      },
    },
  },
  buttons: {
    primary: {
      fontFamily: '"Nunito Sans", sans-serif',
      minHeight: "44px",
      minWidth: "44px",
      fontSize: [1, 2],
      borderRadius: "24px",
      color: "onPrimary",
      border: "1px solid",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderColor: "primary",
      background: "primary",
      cursor: "pointer",
      transition: "background-color 0.1s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.15s",
      transitionTimingFunction: "ease-in-out",
      boxShadow: "boxShadow1dp",

      "&:hover": {
        boxShadow: "boxShadow3dp",
        background: "primaryHover",
        borderColor: "primaryHover",
      },
    },
    secondary: {
      fontFamily: '"Nunito Sans", sans-serif',
      boxShadow: "boxShadow1dp",
      minHeight: "44px",
      minWidth: "44px",
      fontSize: [1, 2],
      borderRadius: "24px",
      border: "1px solid",
      borderColor: "outline",
      background: "none",
      color: "primary",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "background-color 0.3s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.1s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        boxShadow: "boxShadow3dp",
        background: "outlineButtonHover",
      },
    },

    tertiary: {
      // fontFamily:"Nunito Sans",
      fontFamily: '"Nunito Sans", sans-serif',

      minHeight: "44px",
      minWidth: "44px",
      borderRadius: "24px",
      // marginLeft: -2,
      // px: 2,
      paddingTop: "11px",
      border: "1px solid red",
      borderColor: "outlineVariant",
      background: "transparent",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      color: "primary",
      cursor: "pointer",
      transition: "color 0.15s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.1s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        boxShadow: "boxShadow3dp",
        backgroundColor: "surfaceContainerHighest",
      },
    },
    alert: {
      // fontFamily:"Nunito Sans",
      fontFamily: '"Nunito Sans", sans-serif',

      minHeight: "44px",
      minWidth: "44px",
      borderRadius: "24px",
      // marginLeft: -2,
      // px: 2,
      // py: "11px",
      border: "none",
      background: "transparent",
      color: "onSurface",
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "color 0.3s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.1s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        boxShadow: "boxShadow3dp",
      },
    },
    chip: {
      // fontFamily:"Nunito Sans",
      fontFamily: '"Nunito Sans", sans-serif',

      minHeight: "44px",
      minWidth: "44px",
      fontSize: 0,
      borderRadius: "8px",
      // backgroundColor: "outline",
      // border: "1px solid",
      borderColor: "outlineVariant",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "boxShadow1dp",

      py: 2,
      px: 3,
      fontWeight: 600,
      "&:hover": {
        background: "surfaceContainerHigh",
        color: "onSurface",
        cursor: "pointer",
      },
    },
    icon: {
      color: "#000",
      backgroundColor: "sandstone",
      fontSize: 4,
      padding: 0,
      marginTop: 3,
      "&:hover": {
        cursor: "pointer",
        color: "primary",
      },
    },
    back: {
      color: "white",
      backgroundColor: "background",
      fontSize: 4,
      padding: 0,
      marginTop: 2,
      verticalAlign: "top",
      "&:hover": {
        cursor: "pointer",
        color: "primary",
      },
    },
    previousLink: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      textAlign: "left",
      lineHeight: 1.2,
      padding: 3,
      mt: 3,
      width: "100%",
      borderRadius: "16px",
      backgroundColor: "surfaceContainerHighest",
      border: "0px solid",
      borderColor: "surfaceContainerHighest",
      color: "onSurface",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        // boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.4)",
        textDecoration: "none",
        backgroundColor: "secondaryContainer",
      },

      "& > span:nth-child(1)": {
        // Styles for the first span
        color: "secondary",
        fontSize: 1,
        marginBottom: 3,
        fontWeight: 700,
      },
      "& > span:nth-child(2)": {
        marginBottom: 2,
        color: "onSurface",
        fontSize: 1,
      },
      "& > span:nth-child(3)": {
        fontSize: 4,
      },
    },
    nextLink: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      alignContent: "center",
      textAlign: "right",
      // lineHeight: 1.2,
      padding: 3,
      mt: 3,
      width: "100%",
      borderRadius: "16px",
      border: "0px solid",
      color: "onSurface",
      cursor: "pointer",
      borderColor: "surfaceContainerHighest",
      backgroundColor: "surfaceContainerHighest",
      transition: "background-color 0.3s ease",
      transitionProperty: "box-shadow",
      transitionDuration: "0.3s",
      transitionTimingFunction: "ease-in-out",
      "&:hover": {
        // boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.4)",
        backgroundColor: "secondaryContainer",
        textDecoration: "none",
      },

      // Add any other styles specific to the previous link variant
      "& > span:nth-child(1)": {
        // Styles for the first span
        color: "secondary",
        fontSize: 1,
        marginBottom: 3,
        fontWeight: 700,
      },
      "& > span:nth-child(2)": {
        marginBottom: 2,
        color: "onSurface",
        fontSize: 1,
      },
      "& > span:nth-child(3)": {
        fontSize: 4,
      },
    },
    tabs: {
      marginTop: "20px",
    },
    tabContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: "0.5rem",
      borderTop: "1px solid #2c2a26",
      borderBottom: "1px solid #2c2a26",
      paddingLeft: "6px",
    },
    tab: {
      padding: "0.5rem 1rem",
      border: "none",
      backgroundColor: "transparent",
      cursor: "pointer",
      outline: "none",
      fontSize: "1rem",
      fontWeight: "500",
      textTransform: "uppercase",
      transition: "color 0.3s",
      listStyle: "none",
      display: "inline",
    },
    activeTab: {
      color: "#f2bf48",
      borderBottom: "2px solid #f2bf48",
    },
    reactTabsTabPanel: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "500px",
      margin: "0 auto",
    },
    reactTabsTabPanelDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "500px",
      margin: "0 auto",
    },
  },
});
