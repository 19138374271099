import './src/styles/global.css';
// gatsby-browser.js
import React from 'react';
import { ThemeProvider } from 'theme-ui';
import theme from './src/gatsby-plugin-theme-ui';
import "./src/styles/print.css"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);

